import React, { useState, useEffect, useMemo } from 'react';
import logo from './images/logo-dark.webp';

function calculateTimeLeft(launchDate) {
  const now = new Date();
  const difference = launchDate - now;
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
}

function App() {
  const launchDate = useMemo(() => new Date('2024-08-26T19:00:00Z'), []);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(launchDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(launchDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [launchDate]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col justify-between items-center">
      <header className="text-center flex-grow flex flex-col justify-center items-center font-poppins font-light">
        <img src={logo} alt="Logo" className="mx-auto h-16 w-auto" />
        <h1 className="text-3xl mt-12">T-Minus</h1>
        <div className="mt-6 text-xl">
          {Object.keys(timeLeft).length > 0 ? (
            <span>
              {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
            </span>
          ) : (
            <span>Launching now...</span>
          )}
        </div>
      </header>
      <footer className="w-full text-center p-4 text-xs font-poppins font-extralight">
        &copy; {new Date().getFullYear()} Untitled Endeavors, Inc. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
